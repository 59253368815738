import React, { useEffect } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { ReactComponent as AssociateFile } from "../../Assests/SVG/OthersMixed/associateAiQuey.svg"; // Import your SVG
import "./customComponent.css";

const CustomMarkdownPreview = ({
  source,
  hasAssociateIcon,
  handleAssociate,
  openPopup,
  ...rest
}) => {
  useEffect(() => {
    if (hasAssociateIcon) {
      const preTags = document.querySelectorAll("pre");

      preTags.forEach((preTag, index) => {
        const codeElement = preTag.querySelector("code");
        if (!codeElement) {
          return;
        }

        const dataCodeDiv = preTag.querySelector("div[data-code]");
        if (!dataCodeDiv) {
          return;
        }
        const dataCodeValue = dataCodeDiv.getAttribute("data-code");

        if (preTag.querySelector(".custom-icon-container")) {
          return;
        }

        const newDiv = document.createElement("div");
        newDiv.classList.add("custom-icon-container", "copied");
        newDiv.setAttribute("data-tooltip", "Click to associate");
        newDiv.addEventListener("click", async () => {
          openPopup(true, dataCodeValue);
          newDiv.classList.add("active");
          setTimeout(() => {
            newDiv.classList.remove("active");
          }, 1000);
        });
        newDiv.style.position = "absolute";
        newDiv.style.top = "6px";
        newDiv.style.right = "2.3rem";
        const icon = React.createElement(AssociateFile, {
          style: { width: "13px", height: "13px" },
        });
        ReactDOM.render(icon, newDiv);

        preTag.appendChild(newDiv);
      });
    }
  });

  useEffect(() => {
    const dataCodeElements = document.querySelectorAll("[data-code]");
    dataCodeElements.forEach((element) => {
      element.setAttribute("data-tooltip", "Click to copy");
      const handleClick = () => {
        const dataCodeValue = element.getAttribute("data-code");
        if (dataCodeValue) {
          navigator.clipboard
            .writeText(dataCodeValue)
            .then(() => {
              element.setAttribute("data-tooltip", "Copied!");
              setTimeout(() => {
                element.setAttribute("data-tooltip", "Click to copy");
              }, 1000);
            })
            .catch((err) => {
              console.error("Error copying text:", err);
            });
        }
      };

      element.addEventListener("click", handleClick);
      return () => {
        element.removeEventListener("click", handleClick);
      };
    });
  }, []);

  return (
    <MarkdownPreview
      source={source}
      className="generated-detail-box"
      wrapperElement={{
        "data-color-mode": "dark",
      }}
      {...rest}
    />
  );
};

CustomMarkdownPreview.propTypes = {
  source: PropTypes.any,
};

export default CustomMarkdownPreview;
