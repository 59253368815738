import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { sideBarListColor } from "../../Constants/Constant";
import { sideBarList } from "../../Constants/sideBarListConstant";
import CustomTooltip from "../../Components/Custom/CustomTooltip";
import { Box, Divider, styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getExistThreatProfile } from "../../Services/ThreatProfile/threatProfile.service";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

const StepperTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#112038 ",
    border: "1px solid #1E2B40",
    maxWidth: "none",
    padding: "0.5rem",
    boxShadow: "-2px 2px 8px 0px #0A081175",
  },
}));

const SideBarList = ({
  listItem,
  isExpandMore,
  setSideBarOpen,
  active,
  setActive,
  sideBarOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleClick = () => {
    if (isExpandMore) {
      if (!sideBarOpen) {
        setSideBarOpen(true);
      }
      setOpen(!open);
    }
  };

  const handleNavigate = async (href, header) => {
    if (header === "Threat Profile") {
      const threatProfileExists = await dispatch(
        getExistThreatProfile()
      ).unwrap();
      const destination = threatProfileExists
        ? href
        : "/threat-profile/onboard";
      navigate(destination);
    } else {
      navigate(href);
    }
    setActive(header);
  };

  const getChildActiveIndex = (val, flag) => {
    if (flag && sideBarList) {
      const foundItem = sideBarList?.find(({ header }) => header === active);
      return foundItem ? foundItem.header === val : null;
    }

    if (!listItem?.children) {
      return null;
    }
    const foundChild = listItem.children?.find(
      ({ header }) => header === active
    );
    return foundChild ? foundChild?.header === val : null;
  };

  const getTypographyColor = (header) =>
    getChildActiveIndex(header, true) ? "#0082F9" : "#ffff";

  const getButtonGap = (isSideBarOpen) => (isSideBarOpen ? "1rem" : "5rem");

  const listItemSelectedChildren = `list-item-head ${
    listItem?.children?.find(({ header }) => header === active) || open
      ? "item-selected"
      : ""
  }`;

  const renderExpandIcon = (isExpanded, isOpen) => {
    if (!isExpanded) {
      return null;
    }
    return isOpen ? (
      <ExpandMore sx={{ fill: sideBarListColor.TEXT }} />
    ) : (
      <ExpandLess sx={{ fill: sideBarListColor.TEXT }} />
    );
  };

  const getListItemTextColor = (header) =>
    getChildActiveIndex(header, true) ? "#0082F9" : "#ffff";

  const getTooltipTitle = (isSideBarOpen, header) =>
    isSideBarOpen ? "" : header;

  const getItemClass = (header) =>
    getChildActiveIndex(header, true) ? "item-selected" : "";

  useEffect(() => {
    sideBarList.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (child.href && location.pathname === child.href) {
            setOpen(true);
            setActive(child.header);
          }
        });
      } else if (item.href && location.pathname === item.href) {
        setActive(item.header);
      }
    });
  }, [location.pathname, setActive]);

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        position: "relative",
        color: sideBarListColor.TEXT,
        "& .MuiTypography-root": {
          color: getTypographyColor(listItem.header),
        },
        "& .MuiListItemIcon-root": {
          minWidth: "0",
        },
        "$ .MuiSvgIcon-root": {
          color: `${sideBarListColor.TEXT} !important`,
        },
        "& .MuiButtonBase-root": {
          transition: "gap 0.5s",
          gap: getButtonGap(sideBarOpen),
        },
      }}
      className={listItem?.id}
      component="nav"
    >
      {isExpandMore ? (
        <>
          <StepperTooltip
            placement="bottom-end"
            title={
              !sideBarOpen ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#112038",
                  }}
                >
                  {listItem?.children.map((item, listIndex) => {
                    const isSelected = getChildActiveIndex(item.header, false);
                    return (
                      <Box
                        key={listIndex}
                        sx={{
                          borderLeft: "2px solid #FFFFFF29",
                          padding: "1rem 0.5rem 0rem 0rem",
                          display: "flex",
                          alignItems: "flex-end",
                          gap: "1rem",
                          fontSize: "1rem",
                          color: `${
                            isSelected ? "#fff" : "#8E97A4"
                          } !important`,
                        }}
                      >
                        <Divider
                          style={{
                            background: "#FFFFFF29",
                            width: "1rem",
                            height: "1px",
                          }}
                        />
                        <span>{item.header}</span>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                ""
              )
            }
          >
            <ListItemButton
              onClick={handleClick}
              className={listItemSelectedChildren}
            >
              <ListItemIcon
                sx={{
                  "& path": {
                    stroke: `${open ? "#0082F9" : "#fff"}`,
                  },
                }}
              >
                {listItem?.headerIcon || ""}
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& .MuiTypography-root": {
                    color: `${open ? "#0082F9" : sideBarListColor.TEXT}`,
                  },
                }}
                primary={listItem?.header}
              />
              {renderExpandIcon(isExpandMore, open)}
            </ListItemButton>
          </StepperTooltip>
          <Collapse
            in={open}
            sx={{
              "& .MuiCollapse-wrapperInner": {
                display: "flex",
                justifyContent: `${sideBarOpen ? "flex-end" : "center"}`,
                paddingTop: "0.5rem",
              },
            }}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              sx={{
                width: "90%",
                borderLeft: `${sideBarOpen ? "1px solid #FFFFFF29" : ""}`,
              }}
              disablePadding
            >
              {listItem?.children.map((item, listIndex) => {
                const isSelected = getChildActiveIndex(item.header, false);
                return (
                  <CustomTooltip
                    title={sideBarOpen ? "" : item.header}
                    placement="right-end"
                    key={listIndex}
                  >
                    <ListItemButton
                      className={item?.id}
                      selected={isSelected}
                      sx={{
                        "& .MuiButtonBase-root": {
                          gap: "0 !important",
                        },
                        position: "relative",
                        maxHeight: !sideBarOpen ? "3rem" : "auto",

                        border: `${
                          isSelected && !sideBarOpen
                            ? "0.083rem solid #FFFFFF29 !important"
                            : ""
                        }`,
                        borderRadius: `${
                          isSelected && !sideBarOpen
                            ? "0.375rem !important"
                            : ""
                        }`,
                        background: `${
                          sideBarOpen ? "" : "transparent"
                        } !important`,
                        paddingLeft: "0 !important",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNavigate(item?.href, item.header);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          "& path": {
                            stroke: `${isSelected ? "#fff" : "#8E97A4"}`,
                          },
                          padding: "0rem 0rem 0rem 0.9rem",
                        }}
                      >
                        {item?.headerIcon ? item?.headerIcon : ""}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          textAlign: "left",
                          textWrap: "auto",
                          "& .MuiTypography-root": {
                            color: `${
                              isSelected ? "#fff" : "#8E97A4"
                            } !important`,
                          },
                        }}
                        primary={item?.header}
                      />
                    </ListItemButton>
                  </CustomTooltip>
                );
              })}
            </List>
          </Collapse>
        </>
      ) : (
        <CustomTooltip
          title={getTooltipTitle(sideBarOpen, listItem?.header)}
          placement="right-end"
        >
          <ListItemButton
            selected={getChildActiveIndex(listItem.header, true)}
            onClick={() => {
              handleNavigate(listItem?.href, listItem.header);
              setActive(listItem.header);
            }}
            className={getItemClass(listItem.header)}
          >
            <ListItemIcon
              sx={{
                "& path": {
                  stroke: `${
                    getChildActiveIndex(listItem.header, true)
                      ? "#0082F9"
                      : "#fff"
                  }`,
                },
              }}
            >
              <ListItemIcon>{listItem?.headerIcon || ""}</ListItemIcon>
            </ListItemIcon>
            <ListItemText
              primary={listItem?.header}
              sx={{
                color: `${getListItemTextColor(listItem.header)} !important`,
              }}
            />
          </ListItemButton>
        </CustomTooltip>
      )}
    </List>
  );
};

SideBarList.propTypes = {
  listItem: PropTypes.object,
  isExpandMore: PropTypes.bool,
  setSideBarOpen: PropTypes.any,
  active: PropTypes.any,
  setActive: PropTypes.any,
  sideBarOpen: PropTypes.bool,
};

export default SideBarList;
