import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";
import {
  removeEmptyKeys,
  removeEmptyKeysWithArray,
} from "../../helper/removeEmptyKeysHelper";

export const topCampaignTechs = createAsyncThunk(
  "campaign/topCampaignTechs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/campaigns/dashboard/top-techniques`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const detectionDashboard = createAsyncThunk(
  "campaign/detectionDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/campaigns/dashboard/detections`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getDetectionDetailsById = createAsyncThunk(
  "campaign/getDetectionDetailsById",
  async (techId, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/campaigns/dashboard/detections/detail?techniqueId=${techId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async ({ requestPayload }, { rejectWithValue }) => {
    try {
      const data = await removeEmptyKeys(requestPayload);
      const cleanReqestObject = await removeEmptyKeysWithArray(data);
      const response = await axiosWrapper.post(
        "campaigns/create",
        cleanReqestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getAllCampaigns = createAsyncThunk(
  "campaign/getAllCampaigns",
  async ({ page = 0, searchValue }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("size", "10");
      if (searchValue) params.append("text_filter", searchValue);
      params.toString();
      const response = await axiosWrapper.get(`campaigns/fetch?${params}`);
      return {
        response,
        page,
        loadmore: page > 0 && response.content && response.content.length > 0,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCampaignById = createAsyncThunk(
  "campaign/getCampaignById",
  async ({ campaignId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`campaigns/${campaignId}`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(`campaigns/${campaignId}`, data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addCampaignNode = createAsyncThunk(
  "campaign/addCampaignNode",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `campaigns/${campaignId}/node`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateCampaignNode = createAsyncThunk(
  "campaign/updateCampaignNode",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(
        `campaigns/${campaignId}/node`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCampaignNode = createAsyncThunk(
  "campaign/deleteCampaignNode",
  async ({ campaignId, nodeId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.delete(
        `/campaigns/${campaignId}/node/${nodeId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addCampaignEdge = createAsyncThunk(
  "campaign/addCampaignEdge",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `/campaigns/${campaignId}/edge`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCampaignEdge = createAsyncThunk(
  "campaign/deleteCampaignEdge",
  async ({ campaignId, edgeId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.delete(
        `/campaigns/${campaignId}/edge/${edgeId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDeploymentsDetection = createAsyncThunk(
  "campaign/getDeploymentsDetection",
  async ({ campaignId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `campaigns/${campaignId}/detections`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deployDetections = createAsyncThunk(
  "campaign/deployDetections",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `campaigns/${id}/detections/deploy?siem=AZURE_SENTINAL`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const testDetections = createAsyncThunk(
  "campaign/testDetections",
  async ({ id, seim, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `campaigns/${id}/detections/test?siem=${seim}`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const validateDetection = createAsyncThunk(
  "campaign/testDetections",
  async ({ id, siem, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `/campaigns/${id}/detections/validate?siem=${siem}&validate=true`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getEmulationsByTechniqueId = createAsyncThunk(
  "campaign/getEmulationsByTechniqueId",
  async (techniqueId, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `campaigns/emulation?techniqueId=${techniqueId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createDetectionQuery = createAsyncThunk(
  "campaign/createDetectionQuery",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `campaigns/${campaignId}/detections`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
