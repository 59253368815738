import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import BackdropLoader from "../Components/Loader/BackdropLoader";
import Layout from "../layout";
import ComingSoon from "../layout/ComingSoon";
import PrivateRoute from "../modules/auth/privateRoute/PrivateRoute";

const SignUp = lazy(() => import("../modules/auth/signUp"));
const SignIn = lazy(() => import("../modules/auth/signIn"));
const IntelFlowDashboard = lazy(() => import("../modules/TID/dashboard"));
const HeaderIntelFlow = lazy(() =>
  import("../modules/TID/intelFlow/HeaderIntelFlow")
);
const Threats = lazy(() => import("../modules/TID/intelFlow/Threats"));
const Indicators = lazy(() => import("../modules/indicators"));
const DetectionCoverageDashboard = lazy(() =>
  import("../modules/TID/detectionCoverage")
);
const CreateIndicator = lazy(() =>
  import("../modules/indicators/create-indicator")
);
const SelectThreat = lazy(() => import("../modules/TID/intelFlow/Create"));
const CreateThreat = lazy(() =>
  import("../modules/TID/intelFlow/Create/Create-Threat-Screen")
);
const EntityScreenDetailed = lazy(() =>
  import("../modules/TID/intelFlow/Threats/ThreatDetailView")
);
const ThreatScenarioModule = lazy(() => import("../modules/threat-scenario"));
const ThreatScenarioScreenDetailed = lazy(() =>
  import(
    "../modules/threat-scenario/threat-scenario-detailed/ThreatScenarioDetailed"
  )
);
const CreateThreatScenario = lazy(() =>
  import("../modules/threat-scenario/create-threat-scenario")
);
const MitreAttack = lazy(() =>
  import("../modules/TID/intelFlow/Attacks-Matrix/MitreAttack")
);
const Remediations = lazy(() =>
  import("../modules/TID/intelFlow/Remediations")
);
const RemediationsDetail = lazy(() =>
  import("../modules/TID/intelFlow/Remediations/RemediationsDetail")
);
const RemediationsCompare = lazy(() =>
  import("../modules/TID/intelFlow/Remediations/RemediationCompare")
);
const HuntAndDetect = lazy(() =>
  import("../modules/TID/intelFlow/Hunt&Detect")
);
const DetectionDetails = lazy(() =>
  import("../modules/TID/intelFlow/Hunt&Detect/DetectionDetails")
);
const Recommendation = lazy(() =>
  import("../modules/TID/intelFlow/Recommendation")
);
const RiskMatrixScreen = lazy(() =>
  import("../modules/TID/intelFlow/Risk-Matrix")
);
const MyTaskScreen = lazy(() => import("../modules/MyTasks"));
const TestScenario = lazy(() => import("../modules/test-scenario"));
const TestScenarioDetails = lazy(() =>
  import("../modules/test-scenario/test-scenario-detail")
);
const DetectionEmulation = lazy(() =>
  import("../modules/test-scenario/test-scenario-detail/DetectionDetailed")
);
const Setting = lazy(() => import("../modules/setting"));
const ShowResults = lazy(() =>
  import("../modules/TID/intelFlow/SerachModule/SearchedResults")
);
const MyWorkspace = lazy(() => import("../modules/myWorkspace"));
const WorkspaceLists = lazy(() =>
  import("../modules/myWorkspace/WorkspaceLists")
);
const ThreatProfile = lazy(() => import("../modules/threat-profile"));
const ThreatProfileList = lazy(() => import("../modules/threat-profile/list"));
const BaselineControlsMain = lazy(() =>
  import("../modules/threat-profile/baseline-controls")
);
const SecurityFramework = lazy(() =>
  import("../modules/threat-profile/baseline-controls/security-framework")
);
const AssocitedProdMain = lazy(() =>
  import("../modules/threat-profile/baseline-controls/associated-products")
);

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <Suspense fallback={<BackdropLoader loading />}>
        <SignIn />
      </Suspense>
    ),
  },
  {
    path: "/signup",
    element: (
      <Suspense fallback={<BackdropLoader loading />}>
        <SignUp />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <PrivateRoute Component={Layout} />,
    children: [
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <IntelFlowDashboard />
          </Suspense>
        ),
      },
      {
        path: "intel-flow",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <HeaderIntelFlow />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: <Threats />,
          },
          {
            path: "graphviews",
            element: <MitreAttack />,
          },
          {
            path: "remediations",
            element: <Remediations />,
          },
          {
            path: "remediations/:id",
            element: <RemediationsDetail />,
          },
          {
            path: "remediations/compare/:controlId",
            element: <RemediationsCompare />,
          },
          {
            path: "hunt-detect",
            element: <HuntAndDetect />,
          },
          {
            path: "hunt-detect/:id",
            element: <DetectionDetails />,
          },
          {
            path: "recommendation",
            element: <Recommendation />,
          },
          {
            path: "risk-matrix",
            element: <RiskMatrixScreen />,
          },
        ],
      },
      {
        path: "entity/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <EntityScreenDetailed />
          </Suspense>
        ),
      },
      // {
      //   path: "threat-scenario/:id",
      //   element: (
      //     <Suspense fallback={<BackdropLoader loading />}>
      //       <ThreatScenarioScreenDetailed />
      //     </Suspense>
      //   ),
      // },
      {
        path: "select-threat",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <SelectThreat />
          </Suspense>
        ),
      },
      {
        path: "create-threat",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreat />
          </Suspense>
        ),
      },
      {
        path: "create-threat/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreat />
          </Suspense>
        ),
      },
      {
        path: "indicators",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <Indicators />
          </Suspense>
        ),
      },
      {
        path: "indicators/create",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateIndicator />
          </Suspense>
        ),
      },
      {
        path: "indicators/edit/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateIndicator type="edit" />
          </Suspense>
        ),
      },
      {
        path: "indicators/create-bulk",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateIndicator type="bulk-create" />
          </Suspense>
        ),
      },
      {
        path: "/detection-coverage-dashboard",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <DetectionCoverageDashboard />
          </Suspense>
        ),
      },
      {
        path: "my-tasks",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <MyTaskScreen />
          </Suspense>
        ),
      },
      {
        path: "my-workspace",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <MyWorkspace />
          </Suspense>
        ),
      },
      {
        path: "my-workspace/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <WorkspaceLists />
          </Suspense>
        ),
      },
      {
        path: "threat-profile",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ThreatProfileList />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/onboard",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ThreatProfile />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/:threatProfileId",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <BaselineControlsMain />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/:threatProfileId/security-framework",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <SecurityFramework />
          </Suspense>
        ),
      },
      {
        path: "/threat-profile/:threatProfileId/associated-products",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <AssocitedProdMain />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <Setting />
          </Suspense>
        ),
      },
      {
        path: "create-campaign",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreatScenario header="Campaign" type="CAMPAIGN" />
          </Suspense>
        ),
      },
      {
        path: "update-campaign/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreatScenario header="Campaign" type="CAMPAIGN" />
          </Suspense>
        ),
      },
      {
        path: "campaign",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <TestScenario />
          </Suspense>
        ),
      },
      {
        path: "campaign/:campaignId",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <TestScenarioDetails />
          </Suspense>
        ),
      },
      {
        path: "campaign/:campaignId/:detectionId",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <DetectionEmulation />
          </Suspense>
        ),
      },
      {
        path: "threat-scenario",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ThreatScenarioModule />
          </Suspense>
        ),
      },
      {
        path: "threat-scenario/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ThreatScenarioScreenDetailed />
          </Suspense>
        ),
      },
      {
        path: "create-threat-scenario",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreatScenario header="THREAT_SCENARIO" type="SCENARIO" />
          </Suspense>
        ),
      },
      {
        path: "update-threat-scenario/:id",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <CreateThreatScenario header="THREAT_SCENARIO" type="SCENARIO" />
          </Suspense>
        ),
      },
      {
        path: "threat-scenario/:id/:stepId",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <DetectionEmulation />
          </Suspense>
        ),
      },
      { path: "risk-management", element: <ComingSoon /> },
      { path: "investication", element: <ComingSoon /> },

      { path: "support", element: <ComingSoon /> },

      {
        path: "search-results",
        element: (
          <Suspense fallback={<BackdropLoader loading />}>
            <ShowResults />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
